@import "reset";

@font-face {
  font-family: 'Product Sans';
  src: url('../../public/fonts/ProductSans-Bold/ProductSans-Bold.woff2') format('woff2'),
  url('../../public/fonts/ProductSans-Bold/ProductSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('../../public/fonts/ProductSans-Regular/ProductSans-Regular.woff2') format('woff2'),
  url('../../public/fonts/ProductSans-Regular/ProductSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


