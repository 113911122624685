.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.43px;
  gap: 6px;
  appearance: none;
  border: none;
  border-radius: 10px;
  margin-bottom: 8px;
  position: relative;
  font-family: var(--font-family);
  font-weight: 600;
  transition: transform .2s var(--first_layer), filter .2s var(--first_layer);

  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &Primary {
    color: white;
    background: #248BDA;
  }

  &Secondary {
    color: white;
    background: #212A33;
    font-weight: 400;
  }

  &:active {
    transform: scale(0.98);
  }
}

.after {
  display: flex;
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
}

.icon {
  display: flex;
}
