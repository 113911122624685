.root {
  position: fixed;
  left: 16px;
  right: 16px;
  top: 100%;
  backdrop-filter: blur(25px);
  font-size: 14px;
  background: #354352;
  padding: 9px 16px;
  border-radius: 14px;
}

.inner {
  display: flex;
  align-items: center;
  gap: 13px;
}